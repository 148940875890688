$fontdir: "./static/fonts/";

@font-face {
  font-family: "Moderat";
  src: url($fontdir + "Moderat-Bold.woff2") format("woff");
  font-style: normal;
  font-weight: normal;
}

// @font-face {
//   font-family: "BTP";
//   src: url($fontdir + "aa_btp.otf") format("opentype");
//   font-style: normal;
//   font-weight: normal;
// }

$headersFont: "Moderat";
$textFont: "Moderat";

$primary-color: black;
$header-bg-color: black;
$blue-color: #0d77ce;
$light-blue-color: #49adff;
$green-color: #09b437;
$light-green-color: #09b437;
$orange-color: #f5af17;
$light-orange-color: #f5af17;
$red-color: #cf3d3d;
$light-red-color: #cf3d3d;
$grey-color: rgba(17, 17, 19, 1);
$placeholder-color: #747474;
$wrapper-bg-color: black;

$navbarLogoWidthSm: 110px;
$navbarLogoWidthMd: 125px;
$navbarLogoWidthLg: 180px;

$miniNavbarLogoWidthSm: 120px;
$miniNavbarLogoWidthMd: 120px;
$miniNavbarLogoWidthLg: 135px;

$navbarDrawerBreakpoint: 900px;

// wrapper min height
// frontpage
$wrapperFWMinheightXs: 80px;
$wrapperFWMinheightSm: 80px;
$wrapperFWMinheightMd: 125px;
$wrapperFWMinheightLg: 125px;
$wrapperFWMinheightXl: 184px;
// static page
$wrapperMinheightXs: 80px;
$wrapperMinheightSm: 80px;
$wrapperMinheightMd: 103px;
$wrapperMinheightLg: 103px;
$wrapperMinheightXl: 162px;

$slideBg: black;
