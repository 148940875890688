@import "../../../theme-settings.scss";

.available-video-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  flex-direction: row;
}
.filters-switcher {
  height: auto;
  @media screen and (min-width: 768px) {
    height: 500px;
  }
  background: black;
  color: white;
  padding: 20px;
  margin: 20px;
  h3 {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }
  .filters {
    margin-top: 20px;
    button {
      text-align: left;
      cursor: pointer;
      display: block;
      background: transparent;
      color: white;
      font-weight: normal;
      font-size: 20px;
      border: 0;
      padding: 0;
      margin: 10px 0;
      transition: 0.3s;
      &.mixitup-filter-active,
      &:hover,
      &:focus {
        color: $primary-color;
        outline: 0;
      }
      &.all {
        font-weight: bold;
        margin-top: 20px;
        border: 2px solid white;
        padding: 5px 15px;
        &.mixitup-filter-active,
        &:hover,
        &:focus {
          color: $primary-color;
          border-color: $primary-color;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    margin: 0;
    margin-bottom: 30px;
    .video-title {
      font-size: 35px;
    }
    .video-price {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 1200px) {
    height: 500px;
  }
  @media screen and (min-width: 1400px) {
    height: 500px;
  }
  @media screen and (min-width: 1900px) {
    height: 600px;
  }
}
.video-card {
  height: 500px;
  &.not-published {
    display: none;
    opacity: 0.4;
  }
  &.not-available {
    opacity: 0.6;
    position: relative;
    .video-status {
      &::first-letter {
        text-transform: uppercase;
      }
      padding: 15px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: $primary-color;
      font-weight: bold;
      font-size: 20px;
    }
  }
  background: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .invisible-break {
    display: block;
  }
  .video-thumb {
    background-size: cover;
    background-position: center;
    // height: 100%;
    flex-grow: 1;
    a {
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  .video-desc {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .video-price {
    font-size: 16px;
    font-weight: 500;
  }
  .video-payment {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .video-title {
    line-height: 1.1;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
    font-family: $headersFont;
    a {
      color: black;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
    .video-subtitle {
      display: block;
      font-weight: normal;
      font-size: 20px;
    }
  }
  .video-payment-status {
    button {
      width: 160px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  @media screen and (max-width: 576px) {
    .video-payment .video-payment-status {
      width: 100%;
    }
    .video-payment .video-payment-status button {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .video-payment .video-payment-status button {
      margin-top: 20px;
    }
  }
  @media screen and (min-width: 768px) {
    margin: 0;
    margin-bottom: 30px;
    .video-title {
      font-size: 35px;
      .video-subtitle {
        display: block;
        font-weight: normal;
        font-size: 22px;
      }
    }
    .video-price {
      font-size: 16px;
    }
  }
  @media screen and (min-width: 1200px) {
    height: 500px;
  }
  @media screen and (min-width: 1400px) {
    height: 500px;
  }
  @media screen and (min-width: 1900px) {
    height: 600px;
  }
}

/* Container
---------------------------------------------------------------------- */

.available-video-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}

/* Grid Breakpoints
---------------------------------------------------------------------- */

.mix,
.gap {
  width: 100%;
}

/* 2 Columns */

@media screen and (min-width: 768px) {
  .mix,
  .gap {
    width: calc(100% / 2 - (((2 - 1) * 2rem) / 2));
  }
}

/* 3 Columns */

@media screen and (min-width: 1200px) {
  .mix,
  .gap {
    width: calc(100% / 3 - (((3 - 1) * 2rem) / 3));
  }
}

/* 4 Columns */

@media screen and (min-width: 1400px) {
  .mix,
  .gap {
    width: calc(100% / 4 - (((4 - 1) * 1rem) / 4));
  }
}
