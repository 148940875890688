@import "./theme-settings.scss";

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .ant-input-affix-wrapper {
      max-height: 100%;
      min-height: 26px;
      input.ant-input {
        min-height: 20px;
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .mini-header .mini-header-text {
    h1,
    h2 {
      font-size: 24px !important;
    }
  }
}

// login page
.noauth-layout .fullsize-bg {
  background-color: black;
  // background-position: top 50px right 100px;
  // background-repeat: no-repeat;
  // background-size: cover;
}
.noauth-layout {
  align-items: flex-start;
  background: none;
  @media screen and (min-width: 1024px) {
    padding: 40px 50px;
  }
  > main {
    left: 10%;
  }
  .fullsize-bg {
    z-index: -1;
  }
}
@media screen and (min-width: 768px) {
  .noauth-layout > main .wrapper-content {
    padding: 30px 60px 40px 60px;
  }
}

.path-logowanie,
.path-rejestracja {
  .mini-header-text {
    display: none;
  }
}
a {
  color: white;
  &:hover,
  &:focus {
    color: white;
    opacity: 0.7;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: white;
}
.ant-checkbox-checked::after {
  border-color: white;
}
.languageSelector {
  button:hover,
  button:focus {
    opacity: 0.7;
  }
}
@media screen and (min-width: 768px) {
  .noauth-layout {
    .mini-header-text {
      h1,
      h2 {
        line-height: 1;
        font-size: 17px !important;
      }
    }
    .mini-header {
      padding: 45px;
      padding-bottom: 35px;
    }
    button:not(.ant-alert-close-icon),
    .ant-btn:not(.ant-alert-close-icon) {
      font-size: 13px !important;
    }
    .ant-form-item {
      margin-bottom: 20px;
    }
    .ant-form-item-with-help {
      margin-bottom: 0;
    }
    .ant-alert-message {
      font-size: 12px;
    }
    .ant-result-title {
      font-size: 16px;
    }
    .ant-input {
      font-size: 11px;
    }
    .ant-form-item-has-error .ant-form-item-explain,
    .ant-form-item-has-error .ant-form-item-split {
      font-size: 11px;
      min-height: 20px;
    }
    .ant-input-affix-wrapper {
      max-height: 24px;
    }
    label {
      font-size: 12px;
    }
    .ant-col-12 {
      padding: 10px 5px !important;
    }
    .noauth-layout .teapp-checkbox {
      font-size: 10px;
    }
    a {
      font-size: 11px !important;
    }
    .languageSelector {
      font-size: 12px;
      a {
        font-size: 12px;
      }
    }
    main {
      .wrapper-content {
        padding: 0px 45px 30px 45px;
      }
      min-width: 407px;
      max-width: 407px;
    }
  }
}
.ant-card {
  background: $grey-color;
  color: white;
  .ant-card-body {
    color: white;
  }
}
.noauth-layout {
  .ant-card {
    background: black;
    color: white;
    .single-button {
      color: black;
      text-transform: uppercase;
      &:hover,
      &:focus {
        border-color: white;
        opacity: 0.7;
      }
    }
    .single-button.filled {
      color: white;
      border-color: white;
      text-transform: uppercase;
    }
    .primary-bordered,
    .ant-btn-primary {
      color: white;
      background-color: transparent;
      border-color: white;
      &:hover,
      &:focus {
        color: white;
        border-color: white;
        opacity: 0.7;
      }
    }
  }
}
.transmission {
  background: $grey-color;
}
.auth-layout:not(.full-width) .content-wrapper {
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 900px) {
    margin-top: 0;
    min-height: calc(100vh - 100px);
  }
  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 100px);
  }
  @media screen and (min-width: 1200px) {
    min-height: calc(100vh - 162px);
  }
}
.videoCard {
  background: #c1c1c1;
}
.admin-menu li a {
  color: white;
}
.admin-menu li img {
  filter: invert(1);
}
.admin-menu li:hover a,
.admin-menu li.active a,
.admin-menu li:focus a {
  color: white;
  opacity: 0.6;
  text-decoration: underline;
  img {
    filter: invert(1);
  }
}
.auth-layout.full-width {
  .content-wrapper-full {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 80px);
    @media screen and (min-width: 768px) {
      min-height: calc(100vh - 80px);
    }
    @media screen and (min-width: 900px) {
      min-height: calc(100vh - 120px);
    }
    @media screen and (min-width: 992px) {
      min-height: calc(100vh - 120px);
    }
    @media screen and (min-width: 1200px) {
      min-height: calc(100vh - 179px);
    }
    .content-wrapper {
      min-height: 0 !important;
    }
  }
}
.path-teapp-admin {
  h1 {
    color: white;
  }
  color: black;
}
.auth-layout:not(.path-teapp-admin) {
  .ant-card-body {
    background: $grey-color;
    color: white;
    .mini-header-text {
      margin-top: 20px;
      @media screen and (min-width: 992px) {
        margin-top: 30px;
      }
    }
    .mini-header .mini-header-text h1,
    .mini-header .mini-header-text h2 {
      color: white;
    }
  }

  .single-button.filled,
  .single-button.filled[disabled] {
    background-color: white;
    border-color: white;
    color: $grey-color;
  }
  .ant-btn {
    background-color: transparent;
  }
  .ant-input-affix-wrapper > input.ant-input::placeholder,
  .teapp-input::placeholder {
    // color: rgba(255, 255, 255, 0.75);
    font-weight: normal;
  }
  .single-button.primary-bordered {
    background-color: $primary-color;
    color: white;
  }
  .ant-btn-primary {
    background: transparent;
    border-color: white;
  }
  .mini-footer {
    background: black;
  }
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    color: $primary-color;
  }
  .single-button:hover,
  .single-button:focus {
    background-color: $primary-color;
    color: white;
  }
  #rcc-confirm-button {
    background: transparent !important;
    color: white !important;
    text-decoration: underline;
  }
  .main-navbar .logo > span {
    display: none;
  }
  .auth-layout.full-width .content-wrapper-full,
  .navbar-menu > ul {
    margin-top: 83px;
    @media screen and (min-width: 900px) {
      margin-top: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  .event-card {
    background: $grey-color;
    color: white;
    .event-title a {
      color: white;
    }
  }
  .filters-switcher .filters button.mixitup-filter-active,
  .filters-switcher .filters button:hover,
  .filters-switcher .filters button:focus {
    color: white;
    opacity: 0.7;
  }
  .filters-switcher {
    background-color: black;
    text-transform: uppercase;

    button {
      text-transform: uppercase;
    }
    button.all {
      text-decoration: underline;
      border: none;
      padding: 0;
      color: white;
      background: transparent;
      &:hover,
      &:focus {
        color: white;
      }
    }
  }
  .video-card .video-thumb {
    border: 15px solid white;
    border-width: 15px 15px 0 15px;
  }
  .video-card .video-desc {
    padding: 15px;
  }
  .video-card .video-title a {
    display: flex;
    flex-direction: column-reverse;
  }
  .gift-card {
    background: $grey-color;
  }
  footer .footer-wrapper .footer-left a,
  footer .footer-wrapper .footer-left div,
  footer .footer-wrapper .footer-right .mini-footer {
    color: white;
  }
  footer {
    border-color: white;
  }
  .ant-card-head-title {
    margin: 10px 0;
    font-size: 14px;
  }
  .admin-menu li a {
    color: white;
    img {
      filter: invert(1);
    }
  }
  .event-list .ant-table-cell:last-child svg,
  .user-manager .ant-table-cell:last-child svg,
  .user-manager .ant-list-item svg {
    color: white;
  }
  .admin-panel .admin-block select {
    border-color: white !important;
  }
  .videos-list .visible svg,
  .videos-list .hidden svg {
    color: white;
  }
  .videoCard .ant-card-body .video-details {
    color: white;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td,
  .ant-list-sm .ant-list-item,
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td,
  .ant-table-thead th.ant-table-column-has-sorters,
  .ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
    padding: 16px;
  }
  .ant-table {
    margin: 20px 0;
  }
  .anticon svg {
    color: white;
  }
  .ant-card,
  .ant-table,
  .ant-checkbox-wrapper {
    font-size: 14px;
  }
  .ant-card-body > div {
    background: transparent !important;
  }

  .ant-card {
    background: $grey-color;
    color: white;
  }

  .node,
  .payment-block {
    background-color: $grey-color;
    color: white;
  }
  .ant-select {
    // margin-bottom: 20px;
    font-size: 15px;
  }
  select {
    background: transparent;
  }
  &.path-home,
  &.path-strona-glowna {
    h2 {
      display: none;
    }
  }
  .video-card {
    background: $grey-color;
    color: white;
    &.not-available {
      opacity: 1;
      .video-thumb {
        position: relative;
      }
      .video-status {
        position: absolute;
        color: white;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
      }
    }
    .video-thumb {
      border-color: $grey-color;
      border-width: 0;
    }
    .video-desc {
      .video-title {
        text-transform: uppercase;
        @media screen and (min-width: 992px) {
          font-size: 27px;
        }
        a {
          color: white;
          flex-direction: column;
          .video-subtitle {
            color: rgba(93, 93, 95, 1);
            margin: 10px 0 5px 0;
            @media screen and (min-width: 768px) {
              font-size: 18px;
            }
          }
        }
      }
    }
    .video-payment {
      flex-direction: column-reverse;
      align-items: flex-start;
      text-transform: uppercase;
      .video-payment-status button {
        width: 180px;
      }
      .video-price {
        @media screen and (min-width: 768px) {
          font-size: 18px;
          margin-top: 10px;
          margin-bottom: 25px;
        }
      }
    }
  }
  .ant-btn {
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    border-color: white;
    &.green {
      background: white;
      border-color: white;
      color: $grey-color;
    }
    &.black {
      color: white;
      border-color: white;
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
    &.white {
      border-color: white;
      color: white;
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
    &.blue {
      border-color: $light-blue-color;
      color: $light-blue-color;
    }
    &.ant-btn-primary {
      border-color: white;
    }
    &.red {
      border-color: $red-color;
      color: $red-color;
    }
  }
  .ant-checkbox-wrapper {
    color: white;
  }
  .payment-block {
    .payment-price,
    .payment-checkboxes {
      border-color: white;
    }
  }
  .gift-card {
    color: white;
  }
  footer .footer-wrapper .footer-right .mini-footer {
    span {
      min-width: auto;
    }
    img {
      display: none;
    }
    &:hover,
    &:focus {
      color: white !important;
    }
  }
  .CookieConsent {
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    border-right: 1px solid rgba(255, 255, 255, 0.7);
  }
  @media screen and (min-width: 1200px) {
    footer .footer-wrapper .footer-right {
      flex: 0 0 250px;
    }
    footer .footer-wrapper .footer-left {
      flex: 0 0 calc(100% - 250px);
    }
  }
  footer .footer-wrapper .footer-left {
    a:hover,
    a:focus {
      color: white;
      opacity: 0.7;
    }
  }
  .page-content {
    color: white;
  }
}
.navbar-menu > ul li a:hover,
.navbar-menu > ul li a:focus {
  filter: none !important;
  opacity: 0.7;
  color: white;
}
.navbar-menu > ul li.search-input {
  display: none;
}
.main-navbar {
  .main-navbar-content {
    > span {
      display: none;
    }
  }
}
.ant-card-head {
  background-color: black;
  color: white;
}
.ant-card-bordered {
  border: none;
}
.ant-card-head {
  text-transform: uppercase;
  background: #1e1e21;
}
@media screen and (min-width: 1200px) {
  .main-navbar {
    .main-navbar-content {
      span {
        display: block;
        font-size: 30px;
        text-transform: uppercase;
      }
      > * {
        flex: 0 0 33.3%;
      }
      .logo {
        text-align: center;
        a {
          display: block;
        }
      }
      .right-col {
        text-align: right;
      }
    }
  }
  .main-navbar.searchbar-show .navbar-menu > ul li:last-child {
    margin-right: 0;
  }
}

.main-navbar.searchbar-show .navbar-menu.admin-logged-in > ul {
  align-items: flex-end;
}
.node-content .content-description {
  border-color: white;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: transparent;
}
@media screen and (min-width: 900px) {
  .main-navbar.searchbar-show .navbar-menu.admin-logged-in > ul {
    display: block;
  }
}

.ant-input {
  background: transparent;
  &:hover,
  &:focus {
    border-color: white;
  }
}
.ant-input-affix-wrapper input {
  background: transparent;
}
.ant-input-affix-wrapper {
  background: transparent;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #707070;
  &:hover,
  &:focus {
    border-color: white;
  }
  background: transparent;
}
.ant-form-item-label > label {
  color: white;
}
.admin-panel .admin-block select {
  border-color: #707070 !important;
  &:hover,
  &:focus {
    border-color: white !important;
  }
  color: white;
}
.admin-panel {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  .ant-btn-primary {
    background: white;
    color: black;
    border-color: white;
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
.ant-picker-cell-in-view,
.ant-picker-content th {
  color: black;
}
.ant-picker {
  background: transparent;
  border-color: #707070;
  &:hover,
  &:focus {
    border-color: white;
  }
}
.ant-picker-separator,
.ant-picker-clear {
  color: white;
}
.ant-picker-clear span {
  filter: invert(1);
}
.ant-picker-range .ant-picker-active-bar {
  background-color: white;
}
.ant-picker-suffix {
  color: white;
}
.ant-message-notice-content {
  color: black;
}
.ant-table {
  color: black;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #707070;
}
.ant-btn-secondary {
  color: black;
  opacity: 0.8;
  &:hover,
  &:focus {
    border-color: white;
    color: black;
    opacity: 0.6;
  }
}
.ant-drawer-body {
  background: $grey-color;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  textarea {
    border: 1px solid #707070;
    background: transparent;
    &:hover,
    &:focus {
      border-color: white;
    }
  }
  select {
    border-color: #707070 !important;
  }
}

.ant-drawer-close {
  color: white;
  &:hover,
  &:focus {
    color: white;
    opacity: 0.6;
  }
}
@media screen and (min-width: 768px) {
  .slide .slide-desc {
    left: unset;
    right: 0;
    height: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 30px 50px;
    max-width: 600px;
    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  background: $grey-color;
  border-color: #707070;
}
.slide .slide-desc .slide-title {
  text-transform: uppercase;
}
.slide .slide-desc .slide-text {
  font-weight: normal;
}
@media screen and (min-width: 2200px) {
  .slide {
    min-height: 500px;
    max-height: 500px;
    > .slide-content {
      min-height: 500px;
      max-height: 500px;
    }
  }
}
th.ant-table-cell {
  background-color: #737373 !important;
  color: white !important;
}
td.ant-table-cell {
  background: #c1c1c1;
}
.ant-skeleton-content .ant-skeleton-title {
  background: #545454;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  background: #8c8c8c;
  &:nth-child(2n) {
    background: #ababab;
  }
}

.node {
  scroll-behavior: smooth;
}
.node-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .main-navbar .main-navbar-content {
    justify-content: center;
    position: relative;
  }
  .main-navbar .barsMenu {
    position: absolute;
    top: 0;
    right: 0;
    svg {
      height: 34px;
    }
  }
}
.gift-card .gift-title {
  padding-right: 30px;
  @media screen and (min-width: 1600px) {
    flex: 0 0 420px;
  }
}
.gift-card .gift-desc {
  @media screen and (min-width: 1600px) {
    flex: 0 0 calc(100% - 820px);
  }
}
.ant-alert-with-description {
  background-color: #eae9e3;
  border: 1px solid #d4ceba;
  color: black;
  .anticon svg {
    color: black !important;
  }
  .ant-btn {
    background: black !important;
    border-color: black !important;
  }
}
.ant-message-notice-content {
  color: black;
}
.ant-result-title {
  color: white;
}
@media screen and (min-width: 1200px) {
  .video-card {
    height: 570px;
  }
}
.ant-select-dropdown {
  background-color: black;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #707070;
}
.ant-select-multiple .ant-select-selection-item {
  background-color: #b8b8b8;
  color: black;
}
select {
  background-color: black;
  color: white;
}
.ant-collapse-content {
  border: 1px solid white;
}
.ant-collapse-content,
.ant-collapse {
  background-color: transparent;
}
.ant-collapse-header {
  background-color: white;
}
